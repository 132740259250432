import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { Seo } from '@/layout/Seo';
import { textSm } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Fragment, ReactNode } from 'react';
import { Phone } from './service';

const PostDate = styled.p`
    ${textSm};
    color: ${({ theme }) => theme.colors.gray4};
`;

const Years = () => <Fragment>{new Date().getFullYear() - 1997} years</Fragment>;

const Link = ({ to, children }: { to: string; children: ReactNode }) => (
    <CLink to={to}>{children}</CLink>
);

type BlogPostProps = {
    data: {
        mdx: {
            frontmatter: {
                title: string;
                date: string;
                seoTitle: string;
                seoDescription: string;
                coverAlt: string;
                cover: {
                    og: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    hero: ImageDataLike;
                };
            };
            excerpt: string;
            body: string & React.ReactNode;
        };
    };
    pageContext: {
        image: string;
    };
};

export const BlogPost = ({ data }: BlogPostProps) => {
    const {
        mdx: {
            frontmatter: { title, date, seoTitle, seoDescription, cover, coverAlt },
            excerpt,
            body,
        },
    } = data;

    return (
        <Fragment>
            <Seo
                title={seoTitle ? seoTitle : title}
                description={seoDescription ? seoDescription : excerpt}
                image={cover.og.gatsbyImageData}
            />
            <CommonHero blogTitle={title} img>
                <GatsbyImage
                    image={getImage(cover.hero) as IGatsbyImageData}
                    alt={coverAlt ? coverAlt : 'smiling person'}
                />
            </CommonHero>
            <MDXProvider components={{ Link, Phone, Years }}>
                <Article>
                    <PostDate>{date}</PostDate>
                    <MDXRenderer>{body}</MDXRenderer>
                </Article>
            </MDXProvider>
        </Fragment>
    );
};

export default BlogPost;

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                date
                seoTitle
                seoDescription
                coverAlt
                cover {
                    og: childImageSharp {
                        gatsbyImageData(width: 1200, height: 630)
                    }
                    hero: childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            excerpt
            body
        }
    }
`;
